import React, { useState } from 'react';
import { Table, TableBody, TableHead, TableRow, TableCell, DialogContent, DialogActions } from '@material-ui/core';
import {
  Divider,
  ErrorMessage,
  LabelValue,
  LabelValueRow,
  SubSectionTitle,
  TableContainer,
} from 'components/wpio/EntitiesPersonsShared';
import { Button, DangerButton } from 'components/shared/Button';
import { Modal } from 'components/shared/Modal';
import { shouldShowDivider } from 'components/wpio/EntitiesPersonsUtils';
import { isDataTruthy, formatNumber } from 'utils/utils';

const EntitiesPersonsPublicProcurementSection = ({ publicProcurement, error }) => {
  if (error) return <ErrorMessage>{error}</ErrorMessage>;

  const { lista_BK14, lista_BK21, lista_SL, lista_CST } = publicProcurement;

  const moreThan3 = data => data && data.length > 0 && data.length > 3;

  return (
    <>
      {isDataTruthy(lista_BK14) && (
        <>
          <div>
            <SubSectionTitle>
              Zamówienia publiczne w projektach współfinansowanych ze środków UE – Baza Konkurencyjności 2014
            </SubSectionTitle>
            {(moreThan3(lista_BK14) ? lista_BK14.slice(0, 3) : lista_BK14).map((item, index) => (
              <BK14Item key={index} item={item} index={index} length={lista_BK14.length} />
            ))}
            {moreThan3(lista_BK14) && (
              <ShowAll
                partialTitle="Baza Konkurencyjności 2014"
                publicProcurement={lista_BK14}
                renderComponent={props => <BK14Item {...props} />}
              />
            )}
            {shouldShowDivider([lista_BK21, lista_SL, lista_CST]) && (
              <Divider style={{ marginTop: 16, marginBottom: 32 }} />
            )}
          </div>
        </>
      )}
      {isDataTruthy(lista_BK21) && (
        <>
          <div>
            <SubSectionTitle>
              Zamówienia publiczne w projektach współfinansowanych ze środków UE – Baza Konkurencyjności 2021
            </SubSectionTitle>
            {(moreThan3(lista_BK21) ? lista_BK21.slice(0, 3) : lista_BK21).map((item, index) => (
              <BK21Item key={index} item={item} index={index} length={lista_BK21.length} />
            ))}
            {moreThan3(lista_BK21) && (
              <ShowAll
                partialTitle="Baza Konkurencyjności 2021"
                publicProcurement={lista_BK21}
                renderComponent={props => <BK21Item {...props} />}
              />
            )}
          </div>
          {shouldShowDivider([lista_SL, lista_CST]) && <Divider style={{ marginTop: 16, marginBottom: 32 }} />}
        </>
      )}
      {isDataTruthy(lista_SL) && (
        <>
          <div>
            <SubSectionTitle>
              Zamówienia publiczne w projektach współfinansowanych ze środków UE – SL2014
            </SubSectionTitle>
            {(moreThan3(lista_SL) ? lista_SL.slice(0, 3) : lista_SL).map((item, index) => (
              <Sl14Item key={index} item={item} index={index} length={lista_SL.length} />
            ))}
            {moreThan3(lista_SL) && (
              <ShowAll
                partialTitle="SL2014"
                publicProcurement={lista_SL}
                renderComponent={props => <Sl14Item {...props} />}
              />
            )}
          </div>
          {shouldShowDivider([lista_CST]) && <Divider style={{ marginTop: 16, marginBottom: 32 }} />}
        </>
      )}
      {isDataTruthy(lista_CST) && (
        <div>
          <SubSectionTitle>
            Zamówienia publiczne w projektach współfinansowanych ze środków UE – CST2021
          </SubSectionTitle>
          {(moreThan3(lista_CST) ? lista_CST.slice(0, 3) : lista_CST).map((item, index) => (
            <Cst21Item key={index} item={item} index={index} length={lista_CST.length} />
          ))}
          {moreThan3(lista_CST) && (
            <ShowAll
              partialTitle="CST2021"
              publicProcurement={lista_CST}
              renderComponent={props => <Cst21Item {...props} />}
            />
          )}
        </div>
      )}
    </>
  );
};

const BK14Item = ({ item, index, length }) => {
  const { nr_projektu, nr_ogloszenia, rola, nip, nazwa, oferty, przedmiot_zamowienia, nazwa_i_adres_zwyciezcy } = item;

  return (
    <>
      <LabelValueRow>
        {nr_projektu && <LabelValue label="Numer projektu" value={nr_projektu} />}
        {nr_ogloszenia && <LabelValue label="Numer ogłoszenia" value={nr_ogloszenia} />}
        {rola && <LabelValue label="Rola" value={rola} />}
        {nip && <LabelValue label="Identyfikator Zamawiającego" value={nip} />}
        {nazwa && <LabelValue label="Nazwa Zamawiającego" value={nazwa} />}
      </LabelValueRow>
      {przedmiot_zamowienia && <LabelValue label="Przedmiot zamówienia" value={przedmiot_zamowienia} />}
      <LabelValueRow>
        {oferty && <LabelValue label="Oferty" value={oferty} />}
        {nazwa_i_adres_zwyciezcy && <LabelValue label="Zwycięzca ogłoszenia" value={nazwa_i_adres_zwyciezcy} />}
      </LabelValueRow>
      {index !== length - 1 && <Divider />}
    </>
  );
};

const BK21Item = ({ item, index, length }) => {
  const {
    nr_projektu,
    nr_ogloszenia,
    rola,
    nazwa,
    nr_zamawiajacego,
    typ_nr_zamawiajacego,
    przedmiot_zamowienia,
    nazwa_i_adres_zwyciezcy,
    nr_zwyciezcy,
    typ_nr_zwyciezcy,
    data_wplyniecia_zwycieskiej_oferty,
    cena_zwycieskiej_oferty,
    nazwa_i_adres_oferenta,
    nr_oferenta,
    typ_nr_oferenta,
    data_wplyniecia_oferty,
    cena_oferty,
    lista_ofert,
  } = item;

  return (
    <>
      <LabelValueRow>
        {isDataTruthy(nr_projektu) && (
          <LabelValue
            label={nr_projektu.length > 1 ? 'Numery projektów' : 'Numer projektu'}
            value={nr_projektu}
            multi
          />
        )}
        {nr_ogloszenia && <LabelValue label="Numer ogłoszenia" value={nr_ogloszenia} />}
        {rola && <LabelValue label="Rola" value={rola} />}
        {nazwa && <LabelValue label="Nazwa Zamawiającego" value={nazwa} />}
        {nr_zamawiajacego && typ_nr_zamawiajacego && (
          <LabelValue label={`${typ_nr_zamawiajacego.toUpperCase()} Zamawiającego`} value={nr_zamawiajacego} />
        )}
      </LabelValueRow>
      {przedmiot_zamowienia && <LabelValue label="Przedmiot zamówienia" value={przedmiot_zamowienia} />}
      <LabelValueRow>
        {nazwa_i_adres_zwyciezcy && <LabelValue label="Zwycięzca ogłoszenia" value={nazwa_i_adres_zwyciezcy} />}
        {nr_zwyciezcy && typ_nr_zwyciezcy && (
          <LabelValue label={`${typ_nr_zwyciezcy.toUpperCase()} Zwycięzcy`} value={nr_zwyciezcy} />
        )}
        {data_wplyniecia_zwycieskiej_oferty && (
          <LabelValue label="Data wpłynięcia zwycięskiej oferty" value={data_wplyniecia_zwycieskiej_oferty} />
        )}
        {cena_zwycieskiej_oferty && <LabelValue label="Cena zwycięskiej oferty" value={cena_zwycieskiej_oferty} />}
      </LabelValueRow>
      <LabelValueRow>
        {nazwa_i_adres_oferenta && <LabelValue label="Nazwa i adres Oferenta" value={nazwa_i_adres_oferenta} />}
        {nr_oferenta && typ_nr_oferenta && (
          <LabelValue label={`${typ_nr_oferenta.toUpperCase()} Oferenta`} value={nr_oferenta} />
        )}
        {data_wplyniecia_oferty && (
          <LabelValue label="Data wpłynięcia oferty Oferenta" value={data_wplyniecia_oferty} />
        )}
        {cena_oferty && <LabelValue label="Cena oferty Oferenta" value={cena_oferty} />}
      </LabelValueRow>
      {isDataTruthy(lista_ofert) && (
        <TableContainer component="div">
          <Table size="small" aria-label={`tabela ofert dla projektu nr ${nr_projektu}`}>
            <TableHead>
              <TableRow>
                <TableCell>Nazwa i adres Oferenta</TableCell>
                <TableCell>Numer Oferenta</TableCell>
                <TableCell>Data wpłynięcia oferty</TableCell>
                <TableCell>Cena oferty</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lista_ofert.map(
                (
                  { nazwa_i_adres_oferenta, typ_nr_oferenta, nr_oferenta, data_wplyniecia_oferty, cena_oferty },
                  index
                ) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {nazwa_i_adres_oferenta}
                    </TableCell>
                    <TableCell>{`${typ_nr_oferenta.toUpperCase()} ${nr_oferenta}`}</TableCell>
                    <TableCell>{data_wplyniecia_oferty}</TableCell>
                    <TableCell>{cena_oferty}</TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {index !== length - 1 && <Divider />}
    </>
  );
};

const Sl14Item = ({ item, index, length }) => {
  const {
    numer_projektu,
    tytul_projektu,
    rola,
    nazwa_beneficjenta,
    identyfikator_beneficjenta,
    wartosc_projektu,
    nr_ogloszenia,
    zamawiajacy_nazwa,
    zamawiajacy_nip,
    status,
    data_ogloszenia,
    rodzaj_zamowienia,
    tryb_zamowienia,
    szacowana_wartosc_zamowienia,
    zamowienie_powyzej_progow_ue,
    numer_kontraktu,
    nazwa_wykonawcy,
    nip_wykonawcy,
    status_kontraktu,
    wartosc_kontraktu,
    data_podpisania,
    data_rozwiazania,
  } = item;

  return (
    <>
      <LabelValueRow>
        {numer_projektu && <LabelValue label="Numer projektu" value={numer_projektu} />}
        {tytul_projektu && <LabelValue label="Tytuł projektu" value={tytul_projektu} />}
        {rola && <LabelValue label="Rola" value={rola} />}
        {nazwa_beneficjenta && <LabelValue label="Nazwa Beneficjenta" value={nazwa_beneficjenta} />}
        {identyfikator_beneficjenta && (
          <LabelValue label="Identyfikator Beneficjenta" value={identyfikator_beneficjenta} />
        )}
        {wartosc_projektu != null && (
          <LabelValue label="Wartość projektu" value={formatNumber(parseFloat(wartosc_projektu))} />
        )}
        {nr_ogloszenia && <LabelValue label="Numer ogłoszenia" value={nr_ogloszenia} />}
        {zamawiajacy_nazwa && <LabelValue label="Nazwa Zamawiającego" value={zamawiajacy_nazwa} />}
        {zamawiajacy_nip && <LabelValue label="Identyfikator Zamawiającego" value={zamawiajacy_nip} />}
        {status && <LabelValue label="Status postępowania" value={status} />}
        {data_ogloszenia && <LabelValue label="Data ogłoszenia" value={data_ogloszenia.split(' ')[0]} />}
        {rodzaj_zamowienia && <LabelValue label="Rodzaj zamówienia" value={rodzaj_zamowienia} />}
        {tryb_zamowienia && <LabelValue label="Tryb udzielenia zamówienia" value={tryb_zamowienia} />}
        {szacowana_wartosc_zamowienia != null && (
          <LabelValue
            label="Szacunkowa wartość zamówienia"
            value={formatNumber(parseFloat(szacowana_wartosc_zamowienia))}
          />
        )}
        {zamowienie_powyzej_progow_ue && (
          <LabelValue label="Zamówienie powyżej progów unijnych" value={zamowienie_powyzej_progow_ue} />
        )}
        {numer_kontraktu && <LabelValue label="Numer kontraktu" value={numer_kontraktu} />}
        {nazwa_wykonawcy && <LabelValue label="Nazwa Wykonawcy" value={nazwa_wykonawcy} />}
        {nip_wykonawcy && <LabelValue label="Identyfikator Wykonawcy" value={nip_wykonawcy} />}
        {status_kontraktu && <LabelValue label="Status kontraktu" value={status_kontraktu} />}
        {wartosc_kontraktu != null && (
          <LabelValue label="Wartość kontraktu" value={formatNumber(parseFloat(wartosc_kontraktu))} />
        )}
        {data_podpisania && <LabelValue label="Data podpisania" value={data_podpisania.split(' ')[0]} />}
        {data_rozwiazania && <LabelValue label="Data rozwiązania" value={data_rozwiazania.split(' ')[0]} />}
      </LabelValueRow>
      {index !== length - 1 && <Divider />}
    </>
  );
};

const Cst21Item = ({ item, index, length }) => {
  if (item.rola !== 'Podwykonawca') {
    [
      'numer_kontraktu_podwykonawca',
      'podwykonawca_nazwa',
      'podwykonawca_identyfikator',
      'status_kontraktu_podwykonawca',
      'wartosc_kontraktu_podwykonawca',
      'data_podpisania_kontraktu_podwykonawca',
      'data_rozwiazania_kontraktu_podwykonawca',
      'data_wycofania_kontraktu_podwykonawca',
    ].forEach(key => {
      item[key] = null;
    });
  }

  const {
    numer_projektu,
    tytul_projektu,
    rola,
    nazwa_beneficjenta,
    identyfikator_beneficjenta,
    wartosc_projektu,
    nr_ogloszenia,
    nazwa_zamowienia,
    zamawiajacy_nazwa,
    zamawiajacy_identyfikator,
    status_zamowienia,
    rodzaj_zamowienia,
    tryb_zamowienia,
    data_ogloszenia,
    data_uniewaznienia,
    data_wycofania,
    wartosc_zamowienia,
    zamowienie_powyzej_progow_ue,
    numer_kontraktu,
    wykonawca_nazwa,
    wykonawca_identyfikator,
    status_kontraktu,
    wartosc_kontraktu,
    data_podpisania_kontraktu,
    data_rozwiazania_kontraktu,
    data_wycofania_kontraktu,
    kontrakty_z_podwykonawcami,
    numer_kontraktu_podwykonawca,
    podwykonawca_nazwa,
    podwykonawca_identyfikator,
    status_kontraktu_podwykonawca,
    wartosc_kontraktu_podwykonawca,
    data_podpisania_kontraktu_podwykonawca,
    data_rozwiazania_kontraktu_podwykonawca,
    data_wycofania_kontraktu_podwykonawca,
  } = item;

  return (
    <>
      <LabelValueRow>
        {numer_projektu && <LabelValue label="Numer projektu" value={numer_projektu} />}
        {tytul_projektu && <LabelValue label="Tytuł projektu" value={tytul_projektu} />}
        {rola && <LabelValue label="Rola" value={rola} />}

        {nazwa_beneficjenta && <LabelValue label="Nazwa Beneficjenta" value={nazwa_beneficjenta} />}
        {identyfikator_beneficjenta && (
          <LabelValue label="Identyfikator Beneficjenta" value={identyfikator_beneficjenta} />
        )}
        {wartosc_projektu != null && (
          <LabelValue label="Wartość projektu" value={formatNumber(parseFloat(wartosc_projektu))} />
        )}
        {nr_ogloszenia && <LabelValue label="Numer ogłoszenia" value={nr_ogloszenia} />}
        {nazwa_zamowienia && <LabelValue label="Nazwa zamówienia" value={nazwa_zamowienia} />}
        {zamawiajacy_nazwa && <LabelValue label="Nazwa Zamawiającego" value={zamawiajacy_nazwa} />}
        {zamawiajacy_identyfikator && (
          <LabelValue label="Identyfikator Zamawiającego" value={zamawiajacy_identyfikator} />
        )}
        {status_zamowienia && <LabelValue label="Status zamówienia" value={status_zamowienia} />}
        {rodzaj_zamowienia && <LabelValue label="Rodzaj zamówienia" value={rodzaj_zamowienia} />}
        {tryb_zamowienia && <LabelValue label="Tryb udzielenia zamówienia" value={tryb_zamowienia} />}
        {data_ogloszenia && <LabelValue label="Data ogłoszenia" value={data_ogloszenia.split(' ')[0]} />}
        {data_uniewaznienia && <LabelValue label="Data unieważnienia" value={data_uniewaznienia.split(' ')[0]} />}
        {data_wycofania && <LabelValue label="Data wycofania" value={data_wycofania.split(' ')[0]} />}
        {wartosc_zamowienia != null && (
          <LabelValue label="Wartość zamówienia" value={formatNumber(parseFloat(wartosc_zamowienia))} />
        )}
        {zamowienie_powyzej_progow_ue && (
          <LabelValue label="Zamówienie powyżej progów unijnych" value={zamowienie_powyzej_progow_ue} />
        )}
        {numer_kontraktu && <LabelValue label="Numer kontraktu" value={numer_kontraktu} />}
        {wykonawca_nazwa && <LabelValue label="Nazwa Wykonawcy" value={wykonawca_nazwa} />}
        {wykonawca_identyfikator && <LabelValue label="Identyfikator Wykonawcy" value={wykonawca_identyfikator} />}
        {status_kontraktu && <LabelValue label="Status kontraktu" value={status_kontraktu} />}
        {wartosc_kontraktu != null && (
          <LabelValue label="Wartość kontraktu" value={formatNumber(parseFloat(wartosc_kontraktu))} />
        )}
        {data_podpisania_kontraktu && (
          <LabelValue label="Data podpisania kontraktu" value={data_podpisania_kontraktu.split(' ')[0]} />
        )}
        {data_rozwiazania_kontraktu && (
          <LabelValue label="Data rozwiązania kontraktu" value={data_rozwiazania_kontraktu.split(' ')[0]} />
        )}
        {data_wycofania_kontraktu && (
          <LabelValue label="Data wycofania kontraktu" value={data_wycofania_kontraktu.split(' ')[0]} />
        )}
        {kontrakty_z_podwykonawcami && (
          <LabelValue label="Kontrakty z Podwykonawcami" value={kontrakty_z_podwykonawcami} />
        )}
        {numer_kontraktu_podwykonawca && (
          <LabelValue label="Numer kontraktu Podwykonawcy" value={numer_kontraktu_podwykonawca} />
        )}
        {podwykonawca_nazwa && <LabelValue label="Nazwa Podwykonawcy" value={podwykonawca_nazwa} />}
        {podwykonawca_identyfikator && (
          <LabelValue label="Identyfikator Podwykonawcy" value={podwykonawca_identyfikator} />
        )}
        {status_kontraktu_podwykonawca && (
          <LabelValue label="Status kontraktu Podwykonawcy" value={status_kontraktu_podwykonawca} />
        )}
        {wartosc_kontraktu_podwykonawca != null && (
          <LabelValue
            label="Wartość kontraktu Podwykonawcy"
            value={formatNumber(parseFloat(wartosc_kontraktu_podwykonawca))}
          />
        )}
        {data_podpisania_kontraktu_podwykonawca && (
          <LabelValue
            label="Data podpisania kontraktu z Podwykonawcą"
            value={data_podpisania_kontraktu_podwykonawca.split(' ')[0]}
          />
        )}
        {data_rozwiazania_kontraktu_podwykonawca && (
          <LabelValue
            label="Data rozwiązania kontraktu z Podwykonawcą"
            value={data_rozwiazania_kontraktu_podwykonawca.split(' ')[0]}
          />
        )}
        {data_wycofania_kontraktu_podwykonawca && (
          <LabelValue
            label="Data wycofania kontraktu z Podwykonawcą"
            value={data_wycofania_kontraktu_podwykonawca.split(' ')[0]}
          />
        )}
      </LabelValueRow>
      {index !== length - 1 && <Divider />}
    </>
  );
};

const ShowAll = ({ publicProcurement, renderComponent, partialTitle }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setModalIsOpen(true)} variant="contained" color="primary">
        Pokaż wszystkie ({publicProcurement.length})
      </Button>
      <Modal
        isOpen={modalIsOpen}
        title={`Zamówienia publiczne w projektach współfinansowanych ze środków UE - ${partialTitle}`}
        handleClose={() => setModalIsOpen(false)}
        maxWidth="lg"
      >
        <DialogContent>
          {publicProcurement.map((item, index) => (
            <React.Fragment key={index}>
              {renderComponent({
                item,
                index,
                length: publicProcurement.length,
              })}
            </React.Fragment>
          ))}
        </DialogContent>
        <DialogActions>
          <DangerButton style={{ marginLeft: 16 }} variant="outlined" onClick={() => setModalIsOpen(false)}>
            Zamknij
          </DangerButton>
        </DialogActions>
      </Modal>
    </>
  );
};

export default EntitiesPersonsPublicProcurementSection;
