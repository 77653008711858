import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import styled from 'styled-components';
import cytoscape from 'cytoscape';
import cola from 'cytoscape-cola';
import Loader from 'components/shared/Loader';
import CircularLoader from 'components/shared/CircularLoader';
import graphStyles from 'components/wpio/GraphRelations/GraphStyles';
import {
  mapGraphItems,
  mapNewGraphItems,
  prepareGraphElements,
  prepareNewGraphElements,
} from 'components/wpio/GraphRelations/GraphUtils';
import { GraphConfigButton, GraphConfigCard } from 'components/wpio/GraphRelations/GraphConfig';
import GraphEntityPersonCardInfo from 'components/wpio/GraphRelations/GraphEntityPersonCardInfo';
import { getTypeByLocation } from 'components/wpio/EntitiesPersonsUtils';
import { columnViewPxBoundary, LAYOUT, TYPE } from 'components/wpio/EntitiesPersonsConsts';
import EntitiesContext from 'contexts/wpio/EntitiesContext';
import PersonsContext from 'contexts/wpio/PersonsContext';
import EntitiesService from 'services/wpio/EntitiesService';
import PersonsService from 'services/wpio/PersonsService';
import { useWindowSize } from 'hooks/useWindowSize';
import { skanerTheme } from 'utils/skanerTheme';
import { GraphDetailsTogglerButton, ScrollableActionsWrapper } from 'components/wpio/EntitiesPersonsShared';

const StyledWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  background: ${skanerTheme.palette.lightgray};
`;
const StyledLoaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`;
const StyledGraphInstanceWrapper = styled.div`
  height: 100%;
  width: 100%;
  min-height: 300px;
`;
const StyledSnackbar = styled(Snackbar)`
  position: absolute;
`;

const GraphRelations = ({ layout, onChangeLayout }) => {
  const [graphItems, setGraphItems] = useState(null);
  const [graphConfig, setGraphConfig] = useState({ yearsBack: 0 });
  const [graphConfigCardIsVisible, setGraphConfigCardIsVisible] = useState(false);
  const [currentSelectedNode, setCurrentSelectedNode] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [visibilityState, setVisibilityState] = useState(document.visibilityState);

  const graphContainer = useRef(null);
  const graphInstance = useRef();
  const graphLayout = useRef();
  const doubleClickedDetectionRef = useRef(null);

  const location = useLocation();

  const type = getTypeByLocation(location);
  const {
    graph: { graphId, graphData, setGraphImage },
  } = useContext(type === TYPE.ENTITY ? EntitiesContext : PersonsContext);

  const windowSize = useWindowSize();

  const onGraphTap = evt => {
    if (evt.target === graphInstance.current) {
      // Set null to current selected node to hide info panel:
      setCurrentSelectedNode(null);
      // Hide edge labels:
      hideSiblingEdgeLabels();
    }
  };

  const onNodeTap = evt => {
    if (doubleClickedDetectionRef.current && doubleClickedDetectionRef.current === evt.target) {
      // Logic fired when node is double tapped/clicked
      // We need to do this beacause cytoscpae library doesnt support doubleTap event
      // so we neet custom
      doubleClickedDetectionRef.current = null;
      evt.preventDefault();
      evt.stopPropagation();
      evt.target.emit('doubleTap', [evt]);
    } else {
      doubleClickedDetectionRef.current = evt.target;
      setTimeout(() => {
        if (doubleClickedDetectionRef.current && doubleClickedDetectionRef.current === evt.target) {
          // logic on one tap/click:
          doubleClickedDetectionRef.current = null;
          const node = evt.target;
          setGraphConfigCardIsVisible(false);
          setCurrentSelectedNode({ ...node.data(), nodeId: node.id() });
          showSiblingEdgeLabels(node.id());
        }
      }, 500);
    }
  };

  const onNodeDoubleTap = async evt => {
    const node = evt.target;
    setCurrentSelectedNode(null);
    await extendGraph(node.id(), node.data().type);
    showSiblingEdgeLabels(node.id());
  };

  const hideSiblingEdgeLabels = () => {
    graphInstance.current.elements().forEach(edge => {
      if (edge.isEdge()) edge.data('titleIsVisible', false);
    });
  };

  const showSiblingEdgeLabels = nodeId => {
    // Deactivate all edge labels first:
    hideSiblingEdgeLabels();
    // Activate only edge labels which has source or target property to current selected node:
    graphInstance.current
      .filter(element => {
        return element.isEdge() && (element.data('source') === nodeId || element.data('target') === nodeId);
      })
      .forEach(edge => {
        if (edge.isEdge()) edge.data('titleIsVisible', true);
      });
  };

  const onShowRelations = (nodeId, type) => {
    // Set null to current selected node to hide info panel:
    setCurrentSelectedNode(null);
    extendGraph(nodeId, type);
  };

  const extendGraph = async (nodeId, type) => {
    setAlertMessage({
      message: 'Trwa dowiązywanie nowych powiązań.',
      isLoading: true,
    });

    let data = null;
    try {
      data = type === TYPE.ENTITY ? await EntitiesService.getGraph(nodeId) : await PersonsService.getGraph(nodeId);
    } catch (error) {
      setAlertMessage({
        severity: 'error',
        message: 'Wystąpił błąd podczas pobierania powiązań.',
      });
      console.error(`Nie udało się pobrać powiązań dla węzła o id = ${nodeId}`);
    }

    const newItems = mapNewGraphItems(data);
    const newGraphElements = prepareNewGraphElements(
      newItems,
      graphInstance.current.nodes(),
      graphInstance.current.edges(),
      graphConfig,
      nodeId
    );

    if (newGraphElements.length === 0) {
      setAlertMessage({
        severity: 'info',
        message: 'Nie dowiązano żadnych nowych węzłów.',
      });
      return;
    }

    setGraphItems(previousGraphItems => {
      return {
        nodes: [...previousGraphItems.nodes, ...newItems.nodes],
        edges: [...previousGraphItems.edges, ...newItems.edges],
      };
    });

    graphInstance.current.nodes().forEach(node => node.lock());

    graphInstance.current.add([...newGraphElements]);

    graphLayout.current = graphInstance.current.elements().makeLayout({
      name: 'cola',
      edgeLength: 150,
    });
    graphLayout.current.run();

    graphLayout.current.on('layoutstop', () => {
      // Callback that can be called after entity id changed so for destroyed graph we do nothing:
      if (graphInstance.current && !graphInstance.current.destroyed()) {
        graphInstance.current.nodes().forEach(node => node.unlock());
        const nodesSize = graphInstance.current.nodes().size();
        setGraphImage(graphInstance.current.png({ full: nodesSize > 4, scale: 4, bg: '#fff' }));
      }
    });

    setAlertMessage({
      severity: 'success',
      message: 'Zaktualizowano graf o nowe węzły.',
    });
  };

  useEffect(() => {
    const onVisibilityChange = () => {
      if (visibilityState !== 'visible' && document.visibilityState === 'visible') {
        setVisibilityState(document.visibilityState);
      }
    };
    window.addEventListener('visibilitychange', onVisibilityChange);
    return () => window.removeEventListener('visibilitychange', onVisibilityChange);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (graphData?.length === 0 || visibilityState !== 'visible') return;

    if (!graphContainer.current && !graphInstance.current) return;

    // Remove graph instance if exitsts:
    if (graphInstance.current) graphInstance.current.destroy();

    try {
      const items = graphItems || mapGraphItems(graphData);
      setGraphItems(items);
      const elements = prepareGraphElements(items, graphConfig, graphId);

      const graphOptions = {
        elements,
        maxZoom: 2,
        minZoom: 0.5,
      };

      // Use cola layout:
      cytoscape.use(cola);

      // Initialize graph:
      graphInstance.current = cytoscape({
        ...graphOptions,
        style: graphStyles,
        container: graphContainer.current,
        layout: {
          name: 'cola',
          edgeLength: 150,
          stop: () => {
            // Callback that can be called after entity id changed so for destroyed graph we do nothing:
            if (graphInstance.current && !graphInstance.current.destroyed()) {
              const nodesSize = graphInstance.current.nodes().size();
              setGraphImage(graphInstance.current.png({ full: nodesSize > 4, scale: 4, bg: '#fff' }));
            }
          },
        },
      });

      // Load fonts and update graph styles (Mozilla issue):
      Promise.all([document.fonts.load('10px bold Roboto'), document.fonts.load('8px lighter Roboto')]).then(() => {
        graphInstance.current.style().update();
      });

      // Register events:
      graphInstance.current.on('tap', 'node', onNodeTap);
      graphInstance.current.on('tap', onGraphTap);
      graphInstance.current.on('doubleTap', 'node', onNodeDoubleTap);
      window.graf = graphInstance.current;
    } catch (error) {
      console.error('Nie udało się zainicjalizować grafu: ', error);
    }

    // Remove graph instance when component unmounted:
    return () => {
      graphInstance.current && graphInstance.current.destroy();
      setGraphImage(null);
    };
  }, [graphData, visibilityState, graphConfig]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (graphInstance.current) {
      setTimeout(() => {
        graphInstance.current.resize();
        graphInstance.current.fit();
      }, 300);
    }
  }, [layout]);

  if (!graphData && !Array.isArray(graphData)) {
    return (
      <StyledLoaderWrapper>
        <Loader />
      </StyledLoaderWrapper>
    );
  }

  return (
    <StyledWrapper>
      {layout !== LAYOUT.ONLY_GRAPH && (
        <ScrollableActionsWrapper location="left" boundaryElement="#wpio-entity-person-details-header">
          {windowSize.width > columnViewPxBoundary && (
            <GraphDetailsTogglerButton
              onClick={() => {
                if (layout === LAYOUT.BOTH) setGraphConfigCardIsVisible(false);
                onChangeLayout(LAYOUT.ONLY_GRAPH);
              }}
              ariaLabel="zwiń graf"
              direction="left"
            />
          )}
          {!!graphContainer.current && !!graphInstance.current && (
            <GraphConfigButton
              onClick={() => {
                setCurrentSelectedNode(null);
                setGraphConfigCardIsVisible(!graphConfigCardIsVisible);
              }}
            />
          )}
        </ScrollableActionsWrapper>
      )}
      {currentSelectedNode && (
        <GraphEntityPersonCardInfo onShowRelations={onShowRelations} data={currentSelectedNode} />
      )}
      {graphConfigCardIsVisible && (
        <GraphConfigCard
          config={graphConfig}
          onChange={config => {
            if (config.yearsBack !== graphConfig.yearsBack) setGraphConfig(config);
            setGraphConfigCardIsVisible(false);
          }}
          onCancel={() => setGraphConfigCardIsVisible(false)}
        />
      )}
      <StyledGraphInstanceWrapper ref={graphContainer} />
      {alertMessage &&
        (alertMessage.isLoading ? (
          <StyledSnackbar open={true} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert severity="info" elevation={6} variant="filled" icon={false}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <CircularLoader />
                Trwa dowiązywanie nowych powiązań.
              </div>
            </Alert>
          </StyledSnackbar>
        ) : (
          <StyledSnackbar
            open={true}
            autoHideDuration={4000}
            onClose={() => setAlertMessage(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert
              onClose={() => setAlertMessage(null)}
              severity={alertMessage.severity}
              elevation={6}
              variant="filled"
            >
              {alertMessage.message}
            </Alert>
          </StyledSnackbar>
        ))}
    </StyledWrapper>
  );
};

export default GraphRelations;
