import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { TYPE } from 'components/wpio/EntitiesPersonsConsts';

const inputs = [
  {
    name: 'nazwa',
    label: 'Nazwa',
    type: TYPE.ENTITY,
    maxLength: 250,
  },
  {
    name: 'nr_nip',
    label: 'NIP',
    type: TYPE.ENTITY,
    isNumber: true,
    maxLength: 10,
  },
  {
    name: 'nr_krs',
    label: 'KRS',
    type: TYPE.ENTITY,
    isNumber: true,
    maxLength: 10,
  },
  {
    name: 'nr_regon',
    label: 'REGON',
    type: TYPE.ENTITY,
    isNumber: true,
    maxLength: 14,
  },
  {
    name: 'numer_zagraniczny',
    label: 'Numer zagraniczny',
    type: TYPE.ENTITY,
    maxLength: 100,
  },
  {
    name: 'imie',
    label: 'Imię',
    type: TYPE.PERSON,
    maxLength: 100,
  },
  {
    name: 'nazwisko',
    label: 'Nazwisko',
    type: TYPE.PERSON,
    maxLength: 100,
  },
  {
    name: 'nr_pesel',
    label: 'PESEL',
    type: TYPE.PERSON,
    isNumber: true,
    maxLength: 11,
  },
];

const EntitiesPersonsFiltersInputs = ({ formik, type }) => {
  const handleNumberInputChange = event => {
    const { name, value, maxLength } = event.target;
    const inputValue = value.replace(/\D/g, '');
    if (inputValue.length <= maxLength) formik.setFieldValue(name, inputValue);
  };

  const handleInputBlur = event => {
    const { name, value } = event.target;
    if (value.length === 0) return;
    const validationRules = {
      nr_nip: {
        validate: validateNip,
        errorMessage: 'Podany NIP jest nieprawidłowy.',
      },
      nr_regon: {
        validate: validateRegon,
        errorMessage: 'Podany REGON jest nieprawidłowy.',
      },
      nr_pesel: {
        validate: validatePesel,
        errorMessage: 'Podany PESEL jest nieprawidłowy.',
      },
    };
    if (validationRules[name]) {
      const { validate, errorMessage } = validationRules[name];
      if (!validate(value)) formik.setFieldError(name, errorMessage);
      else formik.setFieldError(name, '');
    }
  };

  const validateNip = number => {
    if (number.length !== 10) return false;
    const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
    const checksum = weights.reduce((sum, weight, index) => {
      return sum + weight * parseInt(number[index], 10);
    }, 0);
    const controlDigit = checksum % 11;
    const isValid = controlDigit === parseInt(number[9], 10);
    return isValid;
  };

  const validateRegon = number => {
    const weights9 = [8, 9, 2, 3, 4, 5, 6, 7];
    const weights14 = [2, 4, 8, 5, 0, 9, 7, 3, 6, 1, 2, 4, 8];
    if (number.length === 9) {
      const checksum = number
        .slice(0, 8)
        .split('')
        .reduce((sum, digit, index) => sum + parseInt(digit, 10) * weights9[index], 0);
      let controlDigit = checksum % 11;
      if (controlDigit === 10) controlDigit = 0;
      return controlDigit === parseInt(number[8], 10);
    } else if (number.length === 14) {
      const checksum = number
        .slice(0, 13)
        .split('')
        .reduce((sum, digit, index) => sum + parseInt(digit, 10) * weights14[index], 0);
      let controlDigit = checksum % 11;
      if (controlDigit === 10) controlDigit = 0;
      return controlDigit === parseInt(number[13], 10);
    } else {
      return false;
    }
  };

  const validatePesel = number => {
    if (number.length !== 11) return false;
    const weights = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3, 1];
    const checksum = number.split('').reduce((sum, digit, index) => sum + parseInt(digit, 10) * weights[index], 0);
    const controlDigit = checksum % 10;
    return controlDigit === 0;
  };

  return (
    <>
      {inputs
        .filter(input => input.type === type)
        .map(input => (
          <Grid key={input.name} item xs={12} md={6}>
            <TextField
              id={input.name}
              label={input.label}
              name={input.name}
              value={formik.values[input.name]}
              error={Boolean(formik.errors[input.name])}
              helperText={formik.errors[input.name]}
              inputProps={{ maxLength: input.maxLength }}
              fullWidth
              onBlur={handleInputBlur}
              onChange={input.isNumber ? handleNumberInputChange : formik.handleChange}
            />
          </Grid>
        ))}
    </>
  );
};

export default EntitiesPersonsFiltersInputs;
