import React from 'react';
import { Divider, ErrorMessage, LabelValue, SubSectionTitle } from 'components/wpio/EntitiesPersonsShared';
import { shouldShowDivider } from 'components/wpio/EntitiesPersonsUtils';
import { isDataTruthy } from 'utils/utils';

const EntitiesObjectSection = ({ object, error }) => {
  if (error) return <ErrorMessage>{error}</ErrorMessage>;

  const { dane_ceidg, dane_krs, dane_regon } = object;

  return (
    <>
      {isDataTruthy(dane_krs) && (
        <>
          <SubSectionTitle>Rejestr KRS</SubSectionTitle>
          {dane_krs.dzialalnosc_glowna && <LabelValue label="Działalność główna" value={dane_krs.dzialalnosc_glowna} />}
          {isDataTruthy(dane_krs?.dzialalnosc_pozostala) && (
            <LabelValue label="Działalność pozostała" multi value={dane_krs.dzialalnosc_pozostala} />
          )}
          {shouldShowDivider([dane_ceidg, dane_regon]) && <Divider />}
        </>
      )}
      {isDataTruthy(dane_ceidg) && (
        <>
          <SubSectionTitle>Rejestr CEIDG</SubSectionTitle>
          {dane_ceidg.dzialalnosc_glowna.kod && (
            <LabelValue label="Działalność główna" value={dane_ceidg.dzialalnosc_glowna.kod} />
          )}
          {isDataTruthy(dane_ceidg?.dzialalnosc_pozostala) && (
            <LabelValue
              label="Działalność pozostała"
              value={dane_ceidg.dzialalnosc_pozostala.map(({ kod }, index) => (
                <div key={index}>{kod}</div>
              ))}
            />
          )}
          {shouldShowDivider([dane_regon]) && <Divider />}
        </>
      )}
      {isDataTruthy(dane_regon) && (
        <>
          <SubSectionTitle>Rejestr REGON</SubSectionTitle>
          {isDataTruthy(dane_regon?.dzialalnosc_glowna) && (
            <LabelValue
              label="Działalność główna"
              value={`${dane_regon.dzialalnosc_glowna.kod}, ${dane_regon.dzialalnosc_glowna.nazwa}`}
            />
          )}
          {isDataTruthy(dane_regon?.dzialalnosc_pozostala) && (
            <LabelValue
              label="Działalność pozostała"
              value={dane_regon.dzialalnosc_pozostala.map(({ kod, nazwa }, index) => (
                <div key={index}>
                  {kod}, {nazwa}
                </div>
              ))}
            />
          )}
        </>
      )}
    </>
  );
};

export default EntitiesObjectSection;
