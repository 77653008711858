import React from 'react';
import { Card, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  section: {
    '&:not(:last-child)': {
      marginBottom: 30,
    },
  },
  text: {
    '&:not(:last-child)': {
      marginBottom: 10,
    },
  },
  textListItem: {
    fontSize: 16,
    '&:not(:last-child)': {
      marginBottom: 5,
    },
  },
});

const AccessibilityDeclarationView = () => {
  const classes = useStyles();

  return (
    <Card elevation={3} style={{ padding: 16 }}>
      <Box component="section" className={classes.section}>
        <Typography variant="h4" component="h1" className={classes.text}>
          Deklaracja dostępności serwisu SKANER
        </Typography>
        <Typography component="p" className={classes.text}>
          <span id="a11y-podmiot">Ministerstwo Funduszy i Polityki Regionalnej</span> zobowiązuje się zapewnić
          dostępność swojej strony internetowej zgodnie z przepisami ustawy z 4 kwietnia 2019 r. o dostępności cyfrowej
          stron internetowych i aplikacji mobilnych podmiotów publicznych. Deklaracja dostępności dotyczy strony
          serwisu&nbsp;
          <a id="a11y-url" href="https://skaner.gov.pl" target="_blank" rel="noopener noreferrer nofollow">
            SKANER
          </a>
          .
        </Typography>
        <Typography component="p" className={classes.text}>
          Data publikacji strony internetowej: <span id="a11y-data-publikacja">1 października 2021 r.</span>
        </Typography>
        <Typography component="p" className={classes.text}>
          Data ostatniej dużej aktualizacji: <span id="a11y-data-aktualizacja">21 stycznia 2025 r.</span>
        </Typography>
      </Box>
      <Box component="section" className={classes.section}>
        <Typography variant="h5" component="h2" className={classes.text}>
          Stan dostępności cyfrowej
        </Typography>
        <Typography id="a11y-status" component="p" className={classes.text}>
          Strona internetowa jest zgodna z załącznikiem do ustawy z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron
          internetowych i aplikacji mobilnych podmiotów publicznych.
        </Typography>
      </Box>
      <Box component="section" className={classes.section}>
        <Typography id="a11y-przygotowanie" variant="h5" component="h2" className={classes.text}>
          Przygotowanie deklaracji dostępności
        </Typography>
        <Typography component="p" className={classes.text}>
          Deklarację sporządzono dnia <span id="a11y-data-sporzadzenie">1 października 2021 r.</span>
        </Typography>
        <Typography component="p" className={classes.text}>
          Data ostatniego przeglądu deklaracji: <span id="a11y-data-przeglad">14 marca 2025 r.</span>
        </Typography>
        <Typography component="p" className={classes.text}>
          Deklarację sporządziliśmy na podstawie samooceny w oparciu o&nbsp;
          <a
            href="https://www.gov.pl/attachment/1a3e2bb5-6d60-4897-ac2f-07a8e91e70ed"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Listę kontrolną do badania dostępności cyfrowej strony internetowej v. 2.2
          </a>
          &nbsp;(docx, 0.12MB).
        </Typography>
      </Box>
      <Box component="section" className={classes.section}>
        <Typography id="a11y-skroty" variant="h5" component="h2" className={classes.text}>
          Skróty klawiszowe
        </Typography>
        <Typography component="p" className={classes.text}>
          Na stronie internetowej można korzystać ze standardowych skrótów klawiaturowych.
        </Typography>
      </Box>
      <Box component="section" className={classes.section}>
        <Typography variant="h5" component="h2" className={classes.text}>
          Informacje zwrotne i dane kontaktowe
        </Typography>
        <Typography component="p" className={classes.text}>
          Problemy z dostępnością serwisu SKANER prosimy zgłaszać do Departamentu Koordynacji Wdrażania Funduszy Unii
          Europejskiej - mailowo&nbsp;
          <a href="mailto:SekretariatDKF@mfipr.gov.pl" id="a11y-email">
            SekretariatDKF@mfipr.gov.pl
          </a>
          &nbsp;lub telefonicznie&nbsp;
          <a href="tel:+48 22 273 79 00" id="a11y-telefon">
            +48 22 273 79 00
          </a>
          .
        </Typography>
        <Typography component="p" className={classes.text}>
          Każdy ma prawo wystąpić z żądaniem zapewnienia dostępności cyfrowej tej strony internetowej lub jej elementów.
        </Typography>
        <Typography component="p" className={classes.text}>
          Zgłaszając takie żądanie podaj:
        </Typography>
        <ul>
          <li className={classes.textListItem}>swoje imię i nazwisko</li>
          <li className={classes.textListItem}>swoje dane kontaktowe (np. numer telefonu, e-mail)</li>
          <li className={classes.textListItem}>
            dokładny adres strony internetowej, na której jest niedostępny cyfrowo element lub treść
          </li>
          <li className={classes.textListItem}>
            opis na czym polega problem i jaki sposób rozwiązania byłby dla Ciebie najwygodniejszy
          </li>
        </ul>
        <Typography component="p" className={classes.text}>
          Na Twoje zgłoszenie odpowiemy najszybciej jak to możliwe, nie później niż w ciągu 7 dni od jego otrzymania.
        </Typography>
        <Typography component="p" className={classes.text}>
          Jeżeli ten termin będzie dla nas zbyt krótki poinformujemy Cię o tym. W tej informacji podamy nowy termin, do
          którego poprawimy zgłoszone przez Ciebie błędy lub przygotujemy informacje w alternatywny sposób. Ten nowy
          termin nie będzie dłuższy niż 2 miesiące.
        </Typography>
        <Typography component="p" className={classes.text}>
          Jeżeli nie będziemy w stanie zapewnić dostępności cyfrowej strony internetowej lub treści, wskazanej w Twoim
          żądaniu, zaproponujemy Ci dostęp do nich w alternatywny sposób.
        </Typography>
      </Box>
      <Box component="section" className={classes.section}>
        <Typography id="a11y-procedura" variant="h5" component="h2" className={classes.text}>
          Obsługa wniosków i skarg związanych z dostępnością
        </Typography>
        <Typography component="p" className={classes.text}>
          Jeżeli w odpowiedzi na Twój wniosek o zapewnienie dostępności cyfrowej, odmówimy zapewnienia żądanej przez
          Ciebie dostępności cyfrowej, a Ty nie zgadzasz się z tą odmową, masz prawo złożyć skargę.
        </Typography>
        <Typography component="p" className={classes.text}>
          Skargę masz prawo złożyć także, jeśli nie zgadzasz się na skorzystanie z alternatywnego sposobu dostępu, który
          zaproponowaliśmy Ci w odpowiedzi na Twój wniosek o zapewnienie dostępności cyfrowej.
        </Typography>
        <Typography component="p" className={classes.text}>
          Ewentualną skargę złóż do Departamentu Koordynacji Wdrażania Funduszy Unii Europejskiej, e-mail:&nbsp;
          <a href="mailto:SekretariatDKF@mfipr.gov.pl">SekretariatDKF@mfipr.gov.pl</a>.
        </Typography>
        <Typography component="p" className={classes.text}>
          Po wyczerpaniu wszystkich możliwości skargę można przesłać także do&nbsp;
          <a
            href="https://bip.brpo.gov.pl/pl/content/zlozenie-wniosku-do-rzecznika-praw-obywatelskich"
            target="_blank"
            rel="noopener noreferrer nofollow"
            aria-label="Przejdź do zewnętrznego serwisu, otwiera się w nowej karcie"
            title="Przejście do serwisu zewnętrznego Rzecznika Praw Obywatelskich"
          >
            Rzecznika Praw Obywatelskich
          </a>
          .
        </Typography>
      </Box>
      <Box component="section" className={classes.section}>
        <Typography variant="h5" component="h2" className={classes.text}>
          Pozostałe informacje
        </Typography>
        <Typography id="a11y-architektura" variant="h6" component="h3" className={classes.text}>
          Dostępność architektoniczna
        </Typography>
        <Typography component="p" className={classes.text}>
          Budynek Ministerstwa Funduszy i Polityki Regionalnej znajdujący się w Warszawie przy ul. Wspólnej 2/4 został
          przystosowany do użytkowania przez osoby z niepełnosprawnościami poprzez:
        </Typography>
        <ul>
          <li className={classes.textListItem}>stosowne oznakowanie</li>
          <li className={classes.textListItem}>likwidację barier architektonicznych</li>
          <li className={classes.textListItem}>likwidację barier komunikacyjnych</li>
          <li className={classes.textListItem}>
            umożliwienie dostępu do pomieszczeń urzędu dla osób z niepełnosprawnością
          </li>
          <li className={classes.textListItem}>zastosowanie ergonomicznych rozwiązań</li>
        </ul>
        <Typography component="p" className={classes.text}>
          W szczególności zrealizowano następujące działania:
        </Typography>
        <ol>
          <li className={classes.textListItem}>
            Do obsługi osób z niepełnosprawnością oznakowano drzwi wejściowe do budynku Ministerstwa od ul. Wspólnej.
          </li>
          <li className={classes.textListItem}>
            Przed budynkiem Ministerstwa oznakowano kolorem niebieskim 4 miejsca parkingowe dla osób z
            niepełnosprawnością.
          </li>
          <li className={classes.textListItem}>
            W celu obsługi osób z niepełnosprawnością wykonano nową ladę w oknie podawczym kancelarii z odcinkowym
            obniżeniem blatu.
          </li>
          <li className={classes.textListItem}>
            Zastosowano kontrastowe elementy architektury poprzez wyróżnienie recepcji oraz dziennika podawczego.
          </li>
          <li className={classes.textListItem}>
            Wykonano linie naprowadzające oraz pola uwagi w strefach ogólnodostępnych od wejścia od ul. Wspólnej do
            budynku.
          </li>
          <li className={classes.textListItem}>
            Wykonano wyróżnienie początku i końca wszystkich biegów schodowych, a przed dojściem do schodów na każdej
            kondygnacji zastosowano fakturę ostrzegawczą.
          </li>
          <li className={classes.textListItem}>
            Wykonano podjazd wraz z poręczami w celu umożliwienia dostępu do budynku Ministerstwa osób z
            niepełnosprawnością poruszających się na wózkach.
          </li>
          <li className={classes.textListItem}>
            Przy wejściu numer II od ul. Wspólnej zainstalowano drzwi otwierane automatycznie umożliwiające bezkolizyjne
            wejście do budynku.
          </li>
          <li className={classes.textListItem}>
            Zakupiono schodołaz umożliwiający osobom z niepełnosprawnością korzystanie z dużej sali konferencyjnej nr
            5114B na V piętrze.
          </li>
          <li className={classes.textListItem}>
            Zamontowano platformę dla osób z niepełnosprawnością na V piętrze budynku w sali konferencyjnej nr 5114 A.
          </li>
          <li className={classes.textListItem}>
            Dla osób z niepełnosprawnościami została przystosowana winda D1 z siedziskiem.
          </li>
          <li className={classes.textListItem}>
            Wykonano w windzie D1 dodatkową sygnalizację dźwiękową z pełnymi komunikatami głosowymi określającymi
            kierunek jazdy windy, otwarcie/zamknięcie drzwi, kondygnację, na której zatrzymała się winda.
          </li>
          <li className={classes.textListItem}>
            Dla obsługi osób z niepełnosprawnością zamontowano trzy platformy umożliwiające pokonanie schodów.
          </li>
          <li className={classes.textListItem}>
            Na sali konferencyjnej im. Grażyny Gęsickiej oraz w recepcji obsługującej klientów zainstalowano w podłodze
            pętle indukcyjne umożliwiające wzmocnienie dźwięku dla osób z aparatami słuchowymi.
          </li>
          <li className={classes.textListItem}>
            Na sali konferencyjnej im. Grażyny Gęsickiej zakupiono rampę przenośną umożliwiającą prelegentowi z
            niepełnosprawnością dostanie się na podest z mównicą.
          </li>
          <li className={classes.textListItem}>
            Zakupiono dodatkową, mobilną pętlę indukcyjną wzmacniającą dźwięk na salach konferencyjnych (sala 1105, sala
            5114 AiB) oraz dodatkowo dwie pętle przenośne przeznaczone do rozmów między dwoma lub kilkoma osobami przy
            biurku lub stole.
          </li>
          <li className={classes.textListItem}>
            W ramach modernizacji budynku Ministerstwa dostosowano 8 łazienek na potrzeby osób z niepełnosprawnością.
          </li>
          <li className={classes.textListItem}>
            Wykonano instalacje alarmowe we wszystkich toaletach dla osób z niepełnosprawnością.
          </li>
          <li className={classes.textListItem}>
            W łazience na IV piętrze zainstalowano nasadkę toaletową z podłokietnikami umożliwiającą osobom z
            niepełnosprawnością korzystanie z WC.
          </li>
          <li className={classes.textListItem}>
            Wykonano dodatkowe poręcze na klatkach schodowych oraz trzy poręcze na zewnątrz budynku zgodnie z przepisami
            pożarowymi.
          </li>
          <li className={classes.textListItem}>
            Dokupiono fotele do ewakuacji osób z niepełnosprawnościami oraz zemdlonych (w sumie mamy 25 foteli).
          </li>
          <li className={classes.textListItem}>
            Oznaczono windy posiadające udogodnienia dla osób z niepełnosprawnością.
          </li>
          <li className={classes.textListItem}>
            Wykonano plany tyflograficzne wybranych toalet (przy Sali im. G. Gęsickiej, sali 1105 na I piętrze, sali
            5114 A i B na V piętrze oraz toalet ogólnodostępnych znajdujących się po wejściu do budynku od strony ul.
            Żurawiej).
          </li>
          <li className={classes.textListItem}>
            Zamontowano znaczniki dźwiękowe przed wybranymi salami konferencyjnymi (sala im. G.Gęsickiej, sala 1105 na I
            piętrze oraz sala 5114 A i B na V piętrze) informującymi o rozkładzie sal. Znaczniki współpracują z
            bezpłatną aplikacją INTONAVI. Za pomocą tej aplikacji można wyznaczyć trasę do wybranego znacznika oraz
            zapoznać się z opisem przestrzeni, w której znajduje się znacznik.
          </li>
          <li className={classes.textListItem}>
            Zamontowano znaczniki dźwiękowe przy wejściach do budynku od strony ulic Żurawiej i Wspólnej. Znaczniki te
            informują o wejściu do budynku. Znaczniki współpracują z bezpłatną aplikacją INTONAVI. Za pomocą tej
            aplikacji można wyznaczyć trasę do wybranego znacznika oraz zapoznać się z opisem przestrzeni, w której
            znajduje się znacznik.
          </li>
          <li className={classes.textListItem}>
            Na parterze, przy wejściu głównym do budynku od ulicy Wspólnej oraz przy wejściu do budynku od ulicy
            Żurawiej zainstalowano plany tyflograficzne obrazujące rozkład pomieszczeń w strefie ogólnodostępnej w
            sposób wizualny i dotykowy (w alfabecie Braille’a). Plany mają również znaczniki NFC umożliwiające pobranie
            rzutów rozkładu pomieszczeń zaprezentowanych na planach tyflograficznych.
          </li>
          <li className={classes.textListItem}>
            Poniżej umieszczono również do pobrania rzuty rozkładu pomieszczeń ogólnodostępnych od ul. Wspólnej i od ul.
            Żurawiej.
          </li>
        </ol>
        <Typography id="a11y-komunikacja" variant="h6" component="h3" className={classes.text}>
          Dostępność komunikacyjno - informacyjna
        </Typography>
        <Typography component="p" className={classes.text}>
          Aby skutecznie komunikować się z naszym urzędem, osoby niesłyszące lub słabo słyszące mogą:
        </Typography>
        <ul>
          <li className={classes.textListItem}>
            skorzystać z usługi&nbsp;
            <a
              href="https://tlumacz.migam.org/ministerstwo_funduszy_i_polityki_regionalnej"
              target="_blank"
              rel="noopener noreferrer nofollow"
              title="Przejście do serwisu zewnętrznego Tłumacz Migam"
              aria-label="Przejdź do zewnętrznego serwisu, otwiera się w nowej karcie"
            >
              Tłumacz Migam
            </a>
            , która umożliwia połączenia wideo z tłumaczem języka migowego z poziomu przeglądarki internetowej,
            aplikacji mobilnej oraz dowolnego urządzenia z kamerą. Usługa ta jest dostępna w dni robocze w godzinach
            pracy urzędu 8:15-16:15
          </li>
          <li className={classes.textListItem}>
            napisać pismo/złożyć wniosek na adres: Ministerstwo Funduszy i Polityki Regionalnej, ul. Wspólna 2/4, 00-926
            Warszawa
          </li>
          <li className={classes.textListItem}>
            wysłać e-maila na adres: <a href="mailto:kancelaria@mfipr.gov.pl">kancelaria@mfipr.gov.pl</a>
          </li>
          <li className={classes.textListItem}>
            skontaktować się telefonicznie przy pomocy osoby trzeciej na numer telefonu:&nbsp;
            <a href="tel:+48 22 250 01 30">22 250 01 30</a>
          </li>
          <li className={classes.textListItem}>
            skontaktować się osobiście zgłaszając się w siedzibie urzędu w godzinach urzędowania 8:15-16:15
          </li>
          <li className={classes.textListItem}>
            wysłać wiadomość SMS/MMS na numer telefonu: <a href="tel:+48 662 252 227">662 252 227</a>
          </li>
          <li className={classes.textListItem}>
            wysłać faks na numer telefonu: 22 273 87 32. W treści faksu prosimy o podanie nazwy komórki organizacyjnej
            (biura, departamentu), której sprawa dotyczy
          </li>
        </ul>
        <Typography component="p" className={classes.text}>
          W kontakcie z naszym urzędem osoba słabo słysząca może także skorzystać z systemu pętli indukcyjnej na
          recepcji głównej i w sali konferencyjnej im. Grażyny Gęsickiej.
        </Typography>
        <Typography component="p" className={classes.text}>
          W Ministerstwie wyznaczono&nbsp;
          <a
            href="https://www.gov.pl/web/fundusze-regiony/koordynator-dostepnosci"
            target="_blank"
            rel="noopener noreferrer nofollow"
            title="Przejście do serwisu zewnętrznego"
            aria-label="Przejdź do zewnętrznego serwisu, otwiera się w nowej karcie"
          >
            koordynatora ds. dostępności
          </a>
          .
        </Typography>
      </Box>
    </Card>
  );
};

export default AccessibilityDeclarationView;
